<template>
  <div class="d-flex align-items-center p-1 border-bottom">
    <router-link
      :to="pathToBack()"
    >
      <feather-icon
        class="cursor-pointer"
        icon="ChevronLeftIcon"
        size="16"
      />
    </router-link>
    <div class="pl-1 font-medium-1 font-weight-bold">
      {{ titulo }}
    </div>

  </div>
</template>
<script>
export default {
  name: 'TituloCardComVoltar',
  props: {
    titulo: {
      type: String,
      required: true,
    },
    voltar: {
      type: String,
      required: true,
    },
  },
  methods: {
    pathToBack() {
      return (this.voltar && `/${this.voltar}`) || '/'
    },
  },
}
</script>
