<template>
  <div>

    <b-card
      no-body
      class="mb-0"
    >
      <titulo-card-com-voltar
        :voltar="registroAnterior"
        :titulo="tituloCard()"
      />

      <div class="m-2 px-2">
        <b-row>
          <b-col
            cols="9"
            class="pb-2"
          >
            <div class="font-weight-bold">
              Descrição:
            </div>
            <div>{{ itemData && itemData.sub_tipo.nome || '-' }}</div>
          </b-col>
          <b-col
            cols="4"
            class="pb-2"
          >
            <div class="font-weight-bold">
              Localização:
            </div>
            <div>{{ itemData && itemData.localizacao && itemData.localizacao.nome || '-' }}</div>
          </b-col>
          <b-col
            cols="3"
            class="pb-2"
          >
            <div class="font-weight-bold">
              Tipo:
            </div>
            <div>{{ itemData && itemData.sub_tipo && itemData.sub_tipo.tipo.nome || '-' }}</div>
          </b-col>
          <b-col
            cols="2"
          >
            <div class="font-weight-bold">
              Status:
            </div>
            <div>{{ itemData && itemData.status && itemData.status.nome || '-' }}</div>
          </b-col>
          <b-col
            cols="4"
          >

            <div class="font-weight-bold">
              <b-form-checkbox
                v-model="camposParaExibir"
                value="RegistradoPor"
                plain
              >
                Registrado por:
              </b-form-checkbox>

            </div>
            <div>{{ itemData && itemData.registrado_por.name || '-' }}</div>
          </b-col>
          <b-col
            cols="8"
          >
            <div class="font-weight-bold">
              <b-form-checkbox
                v-model="camposParaExibir"
                value="RegistradoEm"
                plain
              >
                Registro em:
              </b-form-checkbox>

            </div>
            <div>{{ itemData && mascaraDataBr(itemData.created_at) || '-' }}</div>
          </b-col>
          <b-col
            cols="4"
            class="mt-2"
          >
            <div class="font-weight-bold">
              <b-form-checkbox
                v-model="camposParaExibir"
                value="AprovadoPor"
                plain
              >
                Aprovador Por:
              </b-form-checkbox>

            </div>
            <div>{{ itemData && itemData.aprovado_por && itemData.aprovado_por.name || '-' }}</div>
          </b-col>
          <b-col
            cols="8"
            class="mt-2"
          >
            <div class="font-weight-bold">
              <b-form-checkbox
                v-model="camposParaExibir"
                value="AprovadoEm"
                plain
              >
                Aprovador em:
              </b-form-checkbox>

            </div>
            <div>{{ itemData && mascaraDataBr(itemData.aprovado_em) }}</div>
          </b-col>
          <b-col
            cols="9"
            class="mt-2"
          >
            <div class="font-weight-bold">
              Observação do registro:
            </div>
            <div>{{ itemData && itemData.descricao || '-' }}</div>
          </b-col>
          <b-col
            cols="6"
            class="mt-2"
          >
            <div class="font-weight-bold">
              Título Abreviado (p/ relatório):
            </div>
            <div>{{ itemData && itemData.titulo_abreviado || 'Não informado' }}</div>

          </b-col>
          <b-col
            cols="12"
            class="mt-2"
          >
            <div class="font-weight-bold">
              Apontamento:
            </div>
            <div>{{ itemData && itemData.observacoes }}</div>

          </b-col>
        </b-row>

      </div>
      <b-row>
        <b-col
          cols="12"
        >
          <b-card
            v-if="itemData && itemData.imagens"
            class="px-2"
          >
            <div class="font-weight-bold font-medium-2 mb-2 pb-1 border-bottom d-flex justify-content-between">
              <div> Imagens Registradas ({{ itemData.imagens.length }})</div>
              <b-button
                variant="outline-primary"

                @click="selecionarTodas(itemData)"
              >
                Selecionar todas
              </b-button>
            </div>
            <b-card-text>
              <h6 class="d-block pb-2">
                Selecione as fotos para aparecem no detalhamento
              </h6>
              <b-row>
                <b-form-group>
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="imagensSelecionadas"
                    name="flavour-2"
                    class="px-2"
                  >
                    <b-form-checkbox
                      v-for="(img, index) in itemData.imagens"
                      :key="img.id"
                      :value="img.id"
                    >
                      <b-card
                        no-body
                        class="mb-0"
                      >
                        <b-img
                          :src="img.url"
                          width="200"
                          height="150"
                          thumbnail
                        />
                      </b-card>
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <div
        class="d-flex flex-column align-items-center justify-content-center pb-3"
      >
        <small class="mb-2">Total de fotos selecionadas: :{{
          imagensSelecionadas.length
        }}</small>
        <b-button
          variant="primary"
          size="md"
          @click="gerarDetalhamentoBtn(itemData)"
        >
          Gerar Detalhamento
        </b-button>

      </div>

    </b-card>

  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BImg,
  BRow,
} from 'bootstrap-vue'
import { mascaraDataBr } from '@/@core/utils/filter'
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Swal from 'sweetalert2'
import TituloCardComVoltar from '@/views/components/componentesPensou/TituloCardComVoltar.vue'

import useItemList from '@/views/apps/registros/list/useItemList'
import userStoreModule from '../registroStoreModule'

export default {
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BButton,
    TituloCardComVoltar,
    BCard,
    BRow,
    BCol,
    BImg,
    BCardText,

  },
  setup() {
    const itemData = ref(null)
    const camposParaExibir = ref([
      'RegistradoEm',
      'AprovadoEm',
      'RegistradoPor',
      'AprovadoPor',
    ])
    const registroAnterior = ref(null)
    const imagensSelecionadas = ref([])

    const REGISTRO_APP_STORE_MODULE_NAME = 'app-registros'

    // Register module
    if (!store.hasModule(REGISTRO_APP_STORE_MODULE_NAME)) store.registerModule(REGISTRO_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REGISTRO_APP_STORE_MODULE_NAME)) store.unregisterModule(REGISTRO_APP_STORE_MODULE_NAME)
    })

    const {
      gerarDetalhamento,
    } = useItemList()

    registroAnterior.value = `obras/registros/${router.currentRoute.params.idObra}`

    const gerarDetalhamentoBtn = itens => {
      gerarDetalhamento({
        idObra: router.currentRoute.params.idObra,
        idRegistro: router.currentRoute.params.idRegistro,
        imagens: imagensSelecionadas.value,
        camposParaExibir: camposParaExibir.value,

      })

      Swal.fire({
        title: 'Gerando detalhamento...',
        html: 'Seu detalhamento está sendo gerado, aguarde...',

        icon: 'info',
        didOpen: () => {
          Swal.showLoading()
        },
        backdrop: false,

      })
    }

    store.dispatch('app-registros/fetchRegistro', {
      idObra: router.currentRoute.params.idObra,
      idRegistro: router.currentRoute.params.idRegistro,
    })
      .then(response => {
        itemData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          itemData.value = undefined
        }
      })
    const tituloCard = () => {
      if (itemData.value) {
        return `Gerar Detalhamento de: ${itemData.value.sub_tipo.nome} - ${itemData.value.localizacao.nome}`
      }
      return 'Descrição não informada'
    }
    const selecionarTodas = item => {
      imagensSelecionadas.value = item.imagens.map(img => img.id)
    }

    return {
      itemData,
      tituloCard,
      registroAnterior,
      mascaraDataBr,
      imagensSelecionadas,
      gerarDetalhamentoBtn,
      camposParaExibir,
      selecionarTodas,
    }
  },
}
</script>
